import "../gallery.css";
import * as React from "react";
import { useMediaQuery } from "react-responsive";
import ImageGallery from "react-image-gallery";
import {
  container,
  eventDate,
  siteTitle,
  menuItem,
} from "../layout.module.css";
import "../sidebar.css";
import Header from "./header";
import Sidebar from "./sidebar";

const imageList = [
  {
    original: require('../images/paris1.jpg').default,
    thumbnail: require('../images/paris1.jpg').default,
  },
  {
    original: require('../images/paris2.jpg').default,
    thumbnail: require('../images/paris2.jpg').default,
  },
  {
    original: require('../images/paris3.jpg').default,
    thumbnail: require('../images/paris3.jpg').default,
  },
  {
    original: require('../images/paris4.jpg').default,
    thumbnail: require('../images/paris4.jpg').default,
  },
  {
    original: require('../images/paris5.jpg').default,
    thumbnail: require('../images/paris5.jpg').default,
  },
  {
    original: require('../images/paris6.jpg').default,
    thumbnail: require('../images/paris6.jpg').default,
  },
  {
    original: require('../images/paris7.jpg').default,
    thumbnail: require('../images/paris7.jpg').default,
  },
  {
    original: require('../images/paris8.jpg').default,
    thumbnail: require('../images/paris8.jpg').default,
  },
  {
    original: require('../images/paris9.jpg').default,
    thumbnail: require('../images/paris9.jpg').default,
  },
  {
    original: require('../images/paris10.jpg').default,
    thumbnail: require('../images/paris10.jpg').default,
  },
  {
    original: require('../images/paris11.jpg').default,
    thumbnail: require('../images/paris11.jpg').default,
  },
  {
    original: require('../images/qutub.jpg').default,
    thumbnail: require('../images/qutub.jpg').default,
  },
  {
    original: require('../images/qutub1.jpg').default,
    thumbnail: require('../images/qutub1.jpg').default,
  },
  {
    original: require('../images/qutub2.jpg').default,
    thumbnail: require('../images/qutub2.jpg').default,
  },
  {
    original: require('../images/qutub3.jpg').default,
    thumbnail: require('../images/qutub3.jpg').default,
  },
  {
    original: require('../images/qutub4.jpg').default,
    thumbnail: require('../images/qutub4.jpg').default,
  },
  {
    original: require('../images/qutub5.jpg').default,
    thumbnail: require('../images/qutub5.jpg').default,
  },
  {
    original: require('../images/qutub6.jpg').default,
    thumbnail: require('../images/qutub6.jpg').default,
  },
  {
    original: require('../images/temple.jpg').default,
    thumbnail: require('../images/temple.jpg').default,
  },
  {
    original: require('../images/temple1.jpg').default,
    thumbnail: require('../images/temple1.jpg').default,
  },
  {
    original: require('../images/temple2.jpg').default,
    thumbnail: require('../images/temple2.jpg').default,
  },
  {
    original: require('../images/temple3.jpg').default,
    thumbnail: require('../images/temple3.jpg').default,
  },
  {
    original: require('../images/philly.jpg').default,
    thumbnail: require('../images/philly.jpg').default,
  },
  {
    original: require('../images/philly1.jpg').default,
    thumbnail: require('../images/philly1.jpg').default,
  },
  {
    original: require('../images/philly2.jpg').default,
    thumbnail: require('../images/philly2.jpg').default,
  },
];

// markup
const GalleryPage = () => {
  // const [style, setStyle] = useState("light");

  function handleClick(src) {
    console.log("this is:", this);
    // Get the expanded image
    var expandImg = document.getElementById("expandedImg");
    // Get the image text
    var imgText = document.getElementById("imgtext");
    // Use the same src in the expanded image as the image being clicked on from the grid
    // expandImg.src = src;
    // // // Use the value of the alt attribute of the clickable image as text inside the expanded image
    imgText.innerHTML = src;
    // // Show the container element (hidden with CSS)
    // expandImg.parentElement.style.display = "block";
  }

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  return (
    <div className={container}>
      <div>
        {isTabletOrMobile && MobileContent()}
        {/* { MobileContent() } */}
      </div>
      <Header></Header>
      <br />
      <br />
      <br />
      <ImageGallery items={imageList} />;
      <br />
      <br />
      <br />
      <div>
        <p className={eventDate}>More photos to come!</p>
      </div>
    </div>
  );
};

const MobileContent = () => {
  return (
    <div>
      <div className={menuItem} id="outer-container">
        <Sidebar
          pageWrapId={"page-wrap"}
          outerContainerId={"outer-container"}
        />
      </div>
      <p className={siteTitle}>GALLERY</p>
    </div>
  );
};

export default GalleryPage;
